<template>
  <div class="card" v-if="result">
    <div class="card-header">
      <h3 class="card-title d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-shield-check me-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M9 12l2 2l4 -4"></path>
          <path
            d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"
          ></path>
        </svg>
        Search Trust Score
      </h3>
    </div>
    <div class="card-body">
      <div class="">
        <score-chart
          :height="128"
          v-if="result"
          :value="result.raw_score"
          :showLabel="true"
        ></score-chart>
        <strong>Improvements:</strong>
        <ul>
          <li v-for="tip in top_tips" :key="tip.id">
            {{ tip.tip }}
          </li>
        </ul>

        <router-link to="/reputation/search-score">
          View more improvements &raquo;
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";

import ScoreChart from "../SearchScore/ScoreChart.vue";

export default {
  name: "reputation.dashboard.reputation-score",
  components: { ScoreChart },
  data: () => {
    return {
      result: null,
      importance: {
        severe: 0,
        high: 1,
        medium: 2,
        low: 3,
        none: 4,
      },
    };
  },
  computed: {
    top_tips() {
      if (!this.result) return [];
      return this.result.improvements
        .map((x) => x)
        .sort((a, b) => {
          return a.importance - b.importance;
        })
        .filter((a) => a.importance < 0)
        .slice(0, 3);
    },
  },
  async mounted() {
    this.result = await ReputationManagementService.getCompanySerpScan(
      this.$auth.activeCompany
    );
  },
};
</script>
