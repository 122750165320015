<template>
  <apexchart :height="height" :options="options" :series="series"></apexchart>
</template>

<script>
export default {
  name: "reputation.search-score.score-chart",
  props: {
    value: Number,
    height: Number,
    showLabel: Boolean,
    hollowSize: Number,
  },
  data: function () {
    return {
      hollow: "0%",
    };
  },
  computed: {
    series() {
      if (!this.value) return [0];
      return [this.value];
    },
    options() {
      return {
        chart: {
          type: "radialBar",
          fontFamily: "inherit",
          animations: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          lineCap: "round",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: this.hollow,
            },
            track: {
              margin: 0,
              background: ["#ddd"],
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                fontSize: `32px`,
                show: this.showLabel,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          colors: [
            function ({ value }) {
              if (value > 90) {
                return "#9ade87";
              } else if (value > 80) {
                return "#cade87";
              } else if (value > 70) {
                return "#deda87";
              } else if (value > 50) {
                return "#deba87";
              } else {
                return "#dea087";
              }
            },
          ],
        },
      };
    },
  },
  mounted() {
    if (!this.hollowSize) {
      this.hollow = "75%";
    } else {
      this.hollow = `${this.hollowSize}%`;
    }
  },
};
</script>
