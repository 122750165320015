<template>
  <div class="card-body d-flex flex-column">
    <h4>Finalize your setup</h4>

    <template v-if="value.style == 'them'">
      <p>
        You're almost ready to start collecting reviews from your customers!
      </p>

      <p>
        You'll be able to send a QR code or a link to your customers that will
        direct them to your survey. As they leave reviews, you will have the
        ability to see and respond to their feedback in your review panel.
      </p>
    </template>
    <template v-else>
      <p>
        You're almost ready to start collecting reviews from your customers!
      </p>

      <p>
        We will send review requests to
        {{ value.contacts.customers.length }} customers.
        <a href="#" @click.prevent="changeStep('contacts')">
          Make changes &raquo;
        </a>
      </p>
    </template>

    <span class="flex-grow-1"></span>

    <p>
      <strong> Finalize your setup to start getting reviews </strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "reputation.dashboard.getting-started.review",
  props: {
    value: Object,
  },
  methods: {
    changeStep(step) {
      this.$emit("change-step", step);
    },
  },
};
</script>
