<template>
  <div class="col-12" v-if="loaded">
    <getting-started
      v-if="reviewFlows.length == 0"
      :company="company"
      v-on:auto-setup="reloadFlows"
    ></getting-started>
    <recent-reviews
      v-if="reviewFlows.length != 0 && recentReviews.length > 0"
      :reviews="recentReviews"
    ></recent-reviews>
    <get-reviews></get-reviews>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
import GettingStarted from "./GettingStarted.vue";
import RecentReviews from "./RecentReviews.vue";
import GetReviews from "./GetReviews.vue";

export default {
  components: { GettingStarted, RecentReviews, GetReviews },
  name: "reputation.dashboard.onecard",
  data: () => {
    return {
      reviewFlows: [],
      company: null,
      recentReviews: [],
      loaded: false,
    };
  },
  methods: {
    async reloadFlows() {
      this.reviewFlows = await ReputationManagementService.getReviewFlows(
        this.$auth.activeCompany
      );
      return true;
    },
    async loadRecentReviews() {
      let r = await ReputationManagementService.getRecentReviews(
        this.$auth.activeCompany
      );
      this.recentReviews = r;
      return true;
    },
  },
  async mounted() {
    await this.loadRecentReviews();
    await this.reloadFlows();
    this.company = await ReputationManagementService.getCompany(
      this.$auth.activeCompany
    );
    setTimeout(() => {
      this.loaded = true;
    }, 200);
  },
};
</script>
