<template>
  <div class="card-body">
    <h4>How do you want us to contact your customers?</h4>

    <div
      class="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-1"
    >
      <label class="form-selectgroup-item flex-fill">
        <input
          type="radio"
          name="form-payment"
          value="cadence"
          class="form-selectgroup-input"
          v-model="value.responses.workflow"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div>
            <strong>Send an email series</strong><br />
            <span class="text-muted">
              We will send a series of 5 emails over a month to your customer
              asking them to leave a review. After they leave a review, they
              will no longer receive emails.
            </span>
          </div>
        </div>
      </label>
      <label class="form-selectgroup-item flex-fill">
        <input
          type="radio"
          name="form-payment"
          value="single"
          class="form-selectgroup-input"
          v-model="value.responses.workflow"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div>
            <strong>Send a single email</strong><br />
            <span class="text-muted">
              After you add a customer to Business Chatter, we will send a
              single email on your behalf requesting the customer leaves a
              review.
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "reputation.dashboard.getting-started.responses",
  props: {
    value: Object,
  },
};
</script>
