<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-md-8">
        <one-card></one-card>
      </div>
      <div class="col-md-4">
        <reputation-score></reputation-score>
      </div>
    </div>
  </div>
</template>

<script>
import OneCard from "./Dashboard/OneCard.vue";
import ReputationScore from "./Dashboard/ReputationScore.vue";
export default {
  components: { OneCard, ReputationScore },
  name: "reputation.dashboard",
  data: () => {
    return {
      loaded: false,
    };
  },
  methods: {},
  async mounted() {
    this.loaded = true;
  },
};
</script>
