<template>
  <div class="card mb-3" v-if="loaded">
    <div class="card-header">
      <h3 class="card-title">Recent Reviews</h3>
    </div>
    <reviews-table :reviews="reviews"></reviews-table>
  </div>
</template>

<script>
import ReviewsTable from "../ReviewFlow/ReviewsTable.vue";

import ReputationManagementService from "../../../services/ReputationManagementService";

export default {
  components: { ReviewsTable },
  name: "reputation.dashboard.recent-reviews",
  props: {
    reviews: Array,
  },
  data: () => {
    return {
      cohort: null,
      reviewFlow: null,
      loaded: false,
    };
  },
  async mounted() {
    let flows = await ReputationManagementService.getReviewFlows(
      this.$auth.activeCompany
    );
    let cohorts = await ReputationManagementService.getReviewFlowCohorts(
      this.$auth.activeCompany,
      flows[0].id
    );
    this.cohort = cohorts[0];
    this.reviewFlow = flows[0];

    this.loaded = true;
  },
};
</script>
