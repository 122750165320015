<template>
  <div class="card shadow border border-primary">
    <div class="card-header">
      <div>
        <h3 class="card-title">Get Reviews</h3>
        <span class="text-muted">
          Let's start gathering reviews and feedback from your customers.
        </span>
      </div>
    </div>

    <component
      :is="steps[stepIndex].name"
      v-model="setup"
      @change-step="changeStep"
    ></component>

    <div class="card-footer">
      <div class="d-flex">
        <button
          @click="previousStep"
          :class="['btn', 'btn-primary', previousSteps ? '' : 'disabled']"
        >
          <span>&laquo; Previous</span>
        </button>

        <div class="steps steps-lime mb-0 mt-1">
          <a
            v-for="(step, idx) in steps"
            :key="`step-${step.name}`"
            href="#"
            :class="['step-item', idx == stepIndex ? 'active' : '']"
            @click.prevent=""
          >
            {{ step.prettyName }}
          </a>
        </div>
        <button
          @click="nextStep"
          :class="['btn', 'btn-primary', stepComplete ? '' : 'disabled']"
        >
          <span v-if="stepIndex != steps.length - 1">Next &raquo;</span>
          <span v-else>Finalize</span>
        </button>
      </div>
    </div>

    <div class="ribbon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-stars"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M17.8 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
        ></path>
        <path
          d="M6.2 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
        ></path>
        <path
          d="M12 9.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  min-height: 450px;
}
</style>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";

import Survey from "./GettingStarted/Survey.vue";
import Responses from "./GettingStarted/Responses.vue";
import Contacts from "./GettingStarted/Contacts.vue";
import Review from "./GettingStarted/Review.vue";

import { required, helpers } from "vuelidate/lib/validators";

const url_friendly = helpers.regex("url_friendly", /^[a-z0-9-]+$/);

export default {
  components: { Survey, Responses, Contacts, Review },
  name: "reputation.dashboard.getting-started",

  props: {
    company: Object,
  },
  data: () => {
    return {
      setup: {
        style: "us",
        survey: {
          name: "",
          slug: "",
        },
        responses: {
          workflow: "cadence",
        },
        contacts: {
          customers: [],
        },
      },
      steps: [
        { name: "survey", prettyName: "Survey" },
        { name: "responses", prettyName: "Responses" },
        { name: "contacts", prettyName: "Contacts" },
        { name: "review", prettyName: "Review" },
      ],
      stepIndex: 0,
      saving: false,
    };
  },
  computed: {
    step() {
      return this.steps[this.stepIndex];
    },
    previousSteps() {
      return this.stepIndex > 0;
    },
    stepComplete() {
      let step = this.step;
      if (this.$v.setup[step.name]) {
        let invalid = this.$v.setup[step.name].$invalid;
        return !invalid;
      } else {
        return true;
      }
    },
  },
  methods: {
    async nextStep() {
      console.log(JSON.stringify(this.setup));

      if (this.stepIndex == this.steps.length - 1) {
        await this.autoSetup();
      }

      if (this.stepIndex == 0 && this.setup.style == "them") {
        this.stepIndex = this.steps.length - 1;
        return;
      }

      this.stepIndex += 1;
      this.stepIndex = Math.max(
        0,
        Math.min(this.stepIndex, this.steps.length - 1)
      );
    },
    previousStep() {
      if (
        this.setup.style == "them" &&
        this.stepIndex == this.steps.length - 1
      ) {
        this.stepIndex = 0;
      }

      this.stepIndex -= 1;
      this.stepIndex = Math.max(
        0,
        Math.min(this.stepIndex, this.steps.length - 1)
      );
    },
    goToStep(index) {
      if (index < this.stepIndex) {
        this.stepIndex = index;
      } else if (index == this.stepIndex + 1 && this.stepComplete) {
        this.stepIndex = index;
      } else {
        while (this.stepIndex < index) {
          if (this.stepComplete) {
            this.stepIndex += 1;
          } else {
            break;
          }
        }
      }
    },
    async autoSetup() {
      this.saving = true;
      await ReputationManagementService.autoSetup(
        this.$auth.activeCompany,
        this.setup
      );
      if (this.$route.path !== `/reputation/get-reviews`) {
        this.$router.push({
          path: `/reputation/get-reviews`,
        });
      } else {
        this.$emit("auto-setup");
      }
    },
    changeStep(step) {
      this.stepIndex = this.steps.findIndex((x) => x.name == step);
    },
  },
  validations: {
    setup: {
      survey: {
        name: {
          required,
        },
        slug: {
          required,
          url_friendly,
        },
      },
      responses: {
        workflow: {
          required,
        },
      },
    },
  },
};
</script>
