<template>
  <div class="card" v-if="loaded">
    <div class="card-header">
      <h3 class="card-title" v-if="waitingReview.length == 0">
        Collect Reviews
      </h3>
      <h3 class="card-title" v-else>Waiting for Reviews</h3>
    </div>
    <div class="table-responsive" v-if="waitingReview.length > 0">
      <table class="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in waitingReview" :key="member.id">
            <td>
              <workflow-status :member="member"></workflow-status>
            </td>
            <td>{{ member.data.first_name }}</td>
            <td>{{ member.data.last_name }}</td>
            <td>{{ member.data.email }}</td>
            <td>{{ member.data.company_name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty" v-if="waitingReview.length == 0">
      <p class="empty-subtitle">
        <strong>
          2 out of 3 buyers find reviews more valuable if they were provided in
          the last three months.
        </strong>
      </p>
      <p class="empty-subtitle">
        Increase your review count by requesting more reviews from your
        customers.
      </p>
      <div class="empty-action">
        <button class="btn btn-primary me-2 mb-2" v-b-modal.new-member-modal>
          Request review from a single customer
        </button>

        <button class="btn btn-outline mb-2" v-b-modal.import-csv-modal>
          Import many customers from a CSV
        </button>
      </div>
    </div>
    <div class="card-footer" v-if="waitingReview.length > 0">
      <a class="btn btn-primary" href="#" v-b-modal.new-member-modal>
        Request review from a single customer
      </a>
      <span class="mx-2">or</span>
      <a class="btn btn-outline" v-b-modal.import-csv-modal href="#">
        import many customers from a CSV.
      </a>
    </div>

    <add-member-modal
      :cohort="cohort"
      :editing="null"
      @saved-member="refreshWaitingReview"
    ></add-member-modal>
    <import-csv-modal
      :cohort="cohort"
      @saved-cohort="refreshWaitingReview"
    ></import-csv-modal>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
import AddMemberModal from "../Cohort/AddMemberModal.vue";
import ImportCsvModal from "../Cohort/ImportCsvModal.vue";
import WorkflowStatus from "../Cohort/WorkflowStatus.vue";

export default {
  components: { AddMemberModal, ImportCsvModal, WorkflowStatus },
  name: "reputation.dashboard.get-reviews",
  data: () => {
    return {
      cohort: null,
      waitingReview: [],
      loaded: false,
    };
  },
  methods: {
    async refreshWaitingReview() {
      this.waitingReview = await ReputationManagementService.getWaitingReviews(
        this.$auth.activeCompany
      );
    },
  },
  async mounted() {
    let flows = await ReputationManagementService.getReviewFlows(
      this.$auth.activeCompany
    );
    let cohorts = await ReputationManagementService.getReviewFlowCohorts(
      this.$auth.activeCompany,
      flows[0].id
    );
    await this.refreshWaitingReview();
    this.cohort = cohorts[0];
    this.loaded = true;
  },
};
</script>
