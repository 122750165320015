<template>
  <div class="card-body d-flex flex-column">
    <div>
      <h4>Add your customers</h4>
      <p>To get started, add your customers or add yourself as a test.</p>
    </div>

    <div
      class="d-flex flex-column align-items-center justify-content-center flex-grow-1"
      v-if="value.contacts.customers.length == 0"
    >
      <div>
        <button v-b-modal.new-member-modal class="btn btn-primary mb-3">
          Add a customer
        </button>
        <a
          href="#"
          v-b-modal.import-csv-modal
          class="btn btn-outline-primary ms-3 mb-3"
        >
          Upload customer list
        </a>
      </div>
      <span class="text-muted">
        Not ready to add a real customer?
        <a @click.prevent="addSelf" href="#">Add yourself as a test.</a>
      </span>
    </div>
    <div class="flex-grow-1" v-else>
      <table class="table table-responsive">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(customer, idx) in value.contacts.customers"
            :key="`customer-${idx}`"
          >
            <td>{{ customer.data.first_name }}</td>
            <td>{{ customer.data.last_name }}</td>
            <td>{{ customer.data.email }}</td>
            <td></td>
            <td>
              <a href="#" @click.prevent="deleteMember(idx)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="7" x2="20" y2="7"></line>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                  <path
                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                  ></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
              </a>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="text-center">
              <a
                href="#"
                v-b-modal.new-member-modal
                class="btn btn-primary btn-sm"
              >
                Add another customer
              </a>

              <a
                href="#"
                v-b-modal.import-csv-modal
                class="btn btn-outline btn-sm ms-3"
              >
                Add customer list
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <import-csv-modal
      :cohort="null"
      @save-member="savedMember"
    ></import-csv-modal>
    <add-member-modal
      :cohort="null"
      :editing="null"
      @saved-member="savedMember"
    ></add-member-modal>
  </div>
</template>

<script>
import AddMemberModal from "../../Cohort/AddMemberModal.vue";
import ImportCsvModal from "../../Cohort/ImportCsvModal.vue";

export default {
  name: "reputation.dashboard.getting-started.contacts",
  components: { AddMemberModal, ImportCsvModal },
  props: {
    value: Object,
  },
  methods: {
    addSelf() {
      this.value.contacts.customers.push({
        data: {
          first_name: this.$auth.user.contact.firstName || "First Name",
          last_name: this.$auth.user.contact.lastName || "Last Name",
          email: this.$auth.user.contact.emailAddress,
          company_name: this.$auth.activeCompany.companyName,
          title: this.$auth.user.contact.title || "Contact",
        },
      });
    },
    deleteMember(memberId) {
      let member = this.value.contacts.customers[memberId];
      let confirmation = confirm(
        `Are you sure you want to permanently delete ${member.data.first_name} from your customer set?`
      );
      console.log(confirmation);
      if (confirmation) {
        this.value.contacts.customers.splice(memberId, 1);
      }
    },
    savedMember(member) {
      console.log(member);
      if (member) {
        let existing = this.value.contacts.customers.filter(
          (c) => c.data.email == member.data.email
        );
        if (existing.length == 0) {
          this.value.contacts.customers.push(member);
        }
      }
    },
  },
};
</script>
