<template>
  <div class="card-body">
    <h4>Get your first review.</h4>
    <div
      class="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-1"
    >
      <label class="form-selectgroup-item flex-fill shadow-sm">
        <input
          type="radio"
          name="form-payment"
          value="us"
          class="form-selectgroup-input"
          v-model="value.style"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div>
            <strong>Let us collect reviews</strong>
            <span class="badge bg-blue-lt ms-2">Popular</span><br />
            <span class="text-muted">
              Upload a list of your customers, and we will send them an email
              encouraging them to share their experience working with your
              company.
            </span>
          </div>
        </div>
      </label>
      <label class="form-selectgroup-item flex-fill">
        <input
          type="radio"
          name="form-payment"
          value="them"
          class="form-selectgroup-input"
          v-model="value.style"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div>
            <strong>Collect reviews on your own</strong><br />
            <span class="text-muted">
              We will give you a link and a QR code which you can share with
              your customers to allow them to leave a review of your company.
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import ReputationManagementService from "../../../../services/ReputationManagementService";

const url_friendly = helpers.regex("url_friendly", /^[a-z0-9-]+$/);

export default {
  name: "reputation.dashboard.getting-started.survey",
  props: {
    value: Object,
  },
  data: () => {
    return {
      company: null,
      business_chatter_url: ReputationManagementService.business_chatter_url,
    };
  },
  methods: {
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
  validations: {
    value: {
      survey: {
        name: {
          required,
        },
        slug: {
          required,
          url_friendly,
        },
      },
    },
  },
  async mounted() {
    this.company = await ReputationManagementService.getCompany(
      this.$auth.activeCompany
    );
    this.value.survey.name = `${this.company.name} Reviews`;
    this.value.survey.slug = this.slugify(`${this.value.survey.name}`);
  },
};
</script>
